:root {
    --black-color: #333333;
    --white-color: #ffffff;
    --light-yellow-color: #f5be1b;
    --yellow-color: #f9a825;
    --orange-color: #e3a54a;
    --dark-orange-color: #d28c32;
    --progress-green: #4dbd74;
    --more-light-green-color: #048042;
    --light-green-color: #036635;
    --green-color: #02522a;
    --blue-color: #4791ff;
    --border-color: #dbdbdb;
    --dark-gray-color: #5c5c5c;
    --gray-color: #999999;
    --light-gray-color: #cccccc;
    --more-light-gray-color: #e8e8e8;
    --background-color: #f4f4f4;
    --imminent-color: #e8c641;
    --expired-color: #ff0000;
    --safety-color: #543a8b;
}

/* Common */
.franchise ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.franchise .alert {
    text-align: left;
}

.text-danger {
    color: var(--expired-color) !important;
}

.text-danger > label {
    color: var(--black-color);
}

.invalid-feedback {
    color: var(--expired-color) !important;
}

.frHelpText {
    font-size: 12px;
    color: var(--gray-color);
    margin: -5px 0 5px;
}

.form-control {
    color: var(--black-color);
}



/* Button */
.frFullButton {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.frGWButton,
.frGreenButton,
.frWhiteButton,
.frBlackButton,
.frGrayButton {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    padding: 7px 18px;
    white-space: nowrap;
}

.frGWButton {
    color: var(--green-color);
    background-color: var(--white-color);
    border: 1px solid var(--green-color);
}

.frGWButton:hover,
.frGWButton:focus,
.frGWButton:active {
    color: var(--green-color);
    opacity: .8;
}

.frGreenButton {
    color: var(--white-color);
    background-color: var(--more-light-green-color) !important;
    border: 1px solid var(--more-light-green-color) !important;
}

.frWhiteButton:disabled,
.frGreenButton:disabled,
.frGWButton:disabled,
.frBlackButton:disabled {
    color: var(--white-color) !important;
    background-color: var(--light-gray-color) !important;
    border-color: var(--more-light-gray-color) !important;
}

.frGreenButton:hover,
.frGreenButton:focus,
.frGreenButton:active {
    color: var(--white-color);
    opacity: .8;
}

.frWhiteButton {
    color: #666;
    background-color: var(--white-color);
    border: 1px solid var(--more-light-gray-color);
}

.frWhiteButton:hover,
.frWhiteButton:focus,
.frWhiteButton:active {
    color: #666;
    background-color: var(--more-light-gray-color);
    opacity: .8;
}

.frBlackButton {
    color: var(--white-color);
    background-color: var(--black-color);
    border: 1px solid var(--black-color);
}

.frBlackButton:hover,
.frBlackButton:focus,
.frBlackButton:active {
    color: var(--white-color);
    opacity: .8;
}

.frGrayButton {
    color: var(--white-color);
    background-color: var(--gray-color);
    border: 1px solid var(--gray-color);
}

.frGrayButton:hover,
.frGrayButton:focus,
.frGrayButton:active {
    color: var(--white-color);
    opacity: .8;
}



/* Header */
.frSearchAutoBox {
    flex-grow: 1;
}

.frSearchAutoBox > div {
    display: flex;
    width: 280px;
    align-items: center;
    background-color: var(--white-color);
    border-radius: 20px;
}

.frSearchAutoBtn {
    width: 24px;
    height: 24px;
    background-image: url(/assets/img/icon/ic_search.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    margin-right: 5px;
    margin-left: 10px;
}

.frSearchAutoBox > div > div {
    height: auto;
    border: none;
    background-color: transparent;
}

.frSearchAutoBox > div > div > div {
    align-items: center;
}

.frSearchAutoBox > div > div > div > span {
    color: var(--gray-color);
}

.frSearchAutoBox input {
    width: 90% !important;
    height: 30px;
    font-size: 14px;
    background-color: transparent;
    border: none;
}

.franchise .app-header .navbar-brand {
    width: 220px;
}

.brand-minimized .franchise .app-header .navbar-brand {
    width: 80px;
}

.franchise .app-header {
    justify-content: flex-start;
    height: 60px;
    background-color: var(--green-color);
    border-bottom: none;
    border-radius: 14px 14px 0 0;
}

.franchise .app-body {
    margin-top: 60px;
}

.frHeaderInfo.frHeaderInfoMobile-show > div:last-child {
    display: block;
}

.frHeaderInfo.frHeaderInfoMobile-show > div:nth-child(3) > img {
    transform: rotate(180deg);
}



/* Footer */
.franchise .app-footer {
    display: block;
    background-color: var(--white-color);
    border-color: var(--light-gray-color);
    padding: 13px 30px;
}

.frFooter {
    display: flex;
    font-size: 14px;
}

.frFooter > div:first-child {
    color: var(--light-gray-color);
}

.frFooter > div:first-child > span {
    margin: 0 6px;
}

.frFooter > div:first-child > a {
    color: var(--gray-color);
}

.frFooter > div:last-child {
    flex-grow: 1;
    text-align: right;
    color: var(--light-gray-color);
}

.frFooter > div:last-child > a {
    font-weight: bold;
    color: var(--black-color);
}


/* sidebar */
.sidebar-fixed .franchise .app-header + .app-body .sidebar {
    height: calc(100vh - 55px);
}

.sidebar-fixed .franchise .sidebar {
    width: 220px;
}

.sidebar-minimized.sidebar-fixed .franchise .sidebar {
    width: 60px;
}

.franchise .sidebar .sidebar-nav,
.sidebar .nav {
    width: 100%;
}

.franchise {
    background-color: var(--background-color);
}

.franchise .main,
.franchise .app-footer {
    margin-left: 220px;
}

.frSidebar-small .franchise .main,
.frSidebar-small .franchise .app-footer {
    margin-left: 60px;
}



/* Container */
.franchise .main .container-fluid {
    padding: 40px 85px 0;
}

.franchise .app-body .main {
    color: var(--black-color);
    background-color: var(--background-color);
}

/* Tooltip */
.ant-tooltip.frTooltip {
    max-width: none;
}

.ant-tooltip.frTooltip .ant-tooltip-inner {
    font-size: 14px;
    color: var(--white-color);
    background-color: var(--black-color);
    opacity: .8;
    padding: 12px;
    border-radius: 10px;
}

.ant-tooltip.frTooltip .ant-tooltip-content .ant-tooltip-arrow {
    bottom: -5px;
}

.ant-tooltip.frTooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
    background-color: var(--black-color);
    opacity: .8;
}

.frTooltipBox {
    display: inline-block;
    position: relative;
}

.frTooltipBox button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    position: absolute;
    top: 0;
    right: -30px;
}

.frTooltipBox button:hover,
.frTooltipBox button:focus,
.frTooltipBox button:focus-visible,
.frTooltipBox button:active {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}



/* Table - react bootstrap table 2 */
.franchise .react-bs-table-no-data {
    color: var(--light-gray-color);
}

.franchise .frTable .table th,
.franchise .frTable .table td {
    vertical-align: middle;
    word-break: break-word;
}

.franchise .frTable .table th.sortable {
    position: relative;
}

.franchise .frTable .table th.sortable span {
    width: 30px;
    letter-spacing: -3px;
    position: absolute;
    transform: translateX(-5px);
    z-index: 1;
}

.franchise .frTable .table th.sortable span::before {
    margin-left: 0;
}

.franchise .frTable .table-hover tbody tr:hover {
    color: var(--black-color);
    background-color: #fafafa;
}

.franchise .frTable .table tbody tr.selection-row {
    background-color: rgba(4,128,66,.1);
}

.frCheckBox {
    padding-left: 0;
}

.frCheckBox > label {
    display: flex;
    align-items: center;
}

.frCheckBox,
.franchise .frTable .selection-cell-header,
.franchise .frTable .selection-cell {
    position: relative;
}

.franchise .frTable .react-bootstrap-table .reset-expansion-style {
    background-color: var(--background-color);
}

.frCheckBox .frCheckBoxLabel {
    margin-right: 6px;
    margin-bottom: 0;
}

.frCheckBox .frCheckBoxLabel,
.franchise .frTable .selection-cell-header::after,
.franchise .frTable .selection-cell::after {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: var(--white-color);
    border: 2px solid var(--more-light-gray-color);
    border-radius: 6px;
    cursor: pointer;
}

.frOnlyOneCheck .frCheckBoxLabel {
    width: 24px;
    height: 24px;
}

.frRadioButton {
    text-align: center;
    padding-left: 0;
    position: relative;
}

.frRadioButton > label {
    display: inline-block;
    width: 100%;
    height: 36px;
    padding-top: 5px;
    cursor: pointer;
    position: absolute;
    top: -6px;
    left: 0;
}

.franchise .frTable .selection-cell-header::after,
.franchise .frTable .selection-cell::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
    cursor: pointer;
}

.frCheckBox input[type='checkbox']:checked + .frCheckBoxLabel,
.franchise .frTable .selection-row .selection-cell-header::after,
.franchise .frTable .selection-row .selection-cell::after {
    background-color: transparent;
    background: url(/assets/img/icon/btn_checkbox_checked.svg) no-repeat 0 0;
    background-size: cover;
    border: none;
}

.frRadioButton input[type='radio']:checked + .frRadioLabel {
    width: 26px;
    height: 26px;
    background-color: transparent;
    background: url(/assets/img/icon/ic_radio.svg) no-repeat 0 0;
    background-size: cover;
    border: none;
    margin-bottom: 0;
    cursor: pointer;
}

.frCheckBox input[type='checkbox'],
.frRadioButton input[type='radio'],
.franchise .frTable .selection-cell-header input[type='checkbox'],
.franchise .frTable .selection-cell input[type='checkbox'] {
    display: none;
}

.frTodoList li .frCheckBoxLabel {
    flex-shrink: 0;
    margin-right: 16px;
}

.frTodoList li .frCheckBox input[type='checkbox'] + label + span {
    color: var(--black-color);
}

.frTodoList li .frCheckBox input[type='checkbox']:checked + label + span,
.frTodoList li .frCheckBox input[type='checkbox']:checked + label + .frTodoCon > .frTodoDesc,
.frTodoList li .frCheckBox input[type='checkbox']:checked + label + .frTodoCon > .frTodoAuthor {
    color: var(--gray-color);
    text-decoration: line-through;
}

.frModalForm .invalid-feedback {
    top: 30px;
}

.frModalForm.todoDetail .col-12 {
    padding: 0 5px;
}

.frModalForm .frTodoList {
    min-height: 288px;
    max-height: 580px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.emptyText {
    color: var(--light-gray-color);
    font-size: 16px;
}

.frModalForm .frTodoList .emptyText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.frModalForm .frTodoList .emptyText:hover {
    background-color: transparent;
}

.frModalForm .frTodoList .form-check {
    margin-top: 0;
    margin-bottom: 0;
}

.frModalForm .frTodoList li {
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 10px;
    transition: .4s ease;
    margin-bottom: 10px;
}

.frModalForm .frTodoList li:hover {
    opacity: .7;
}

.frExpDateBox {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
}

.frExpDateBox select {
    width: 60px;
    margin: 0 8px;
}

.frTodoTitle {
    display: flex;
    align-items: flex-start;
}

.frTodoTitle > div:nth-child(1) {
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.frTodoTitle > div:nth-child(2) {
    width: 45px;
    flex-shrink: 0;
    text-align: right;
}

.frTodoTitle button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0;
    border: 0;
    box-shadow: none;
}

.frTodoTitle button > img {
    width: 100%;
}

.frTodoDetail {
    margin-top: 20px;
}

.frTodoDetail > div {
    margin-bottom: 10px;
}

.frTodoDetail > div:first-child {
    font-size: 16px;
    font-weight: bold;
}

.frTodoDetail > div:last-child {
    margin-bottom: 0;
}

.frTodoAuthor {
    color: var(--gray-color);
    margin-top: 5px;
}

.welcomeModal .row {
    margin-top: 16px;
    margin-bottom: 16px;
}

.welcomeModal .row:last-child {
    margin-bottom: 30px;
}

.welcomeModal .col-12 {
    display: flex;
    font-size: 16px;
}

.welcomeModal .col-12 > div:nth-child(1) {
    width: 80px;
    flex-shrink: 0;
}

.welcomeModal .col-12 > div:nth-child(2) {
    flex-grow: 1;
}

.welcomeModal .col-12 > div:nth-child(2) > p {
    color: var(--green-color);
    margin-top: 10px;
    margin-bottom: 0;
}

.frNoticeList {
    height: 480px;
    overflow: auto;
}

.frNoticeList li {
    display: flex;
    padding: 8px 0;
    cursor: pointer;
}

.frNoticeList li > div:nth-child(1) {
    width: 40px;
    color: var(--gray-color);
}

.frNoticeList li > div:nth-child(2) > div {
    color: var(--gray-color);
}

.frNoticeTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
}

.frNoticeDesc {
    font-size: 16px;
}

.frHygieneConfirm > div {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.frHygieneConfirm > p {
    color: var(--gray-color);
    margin-bottom: 24px;
}

.frHygieneConfirm > p > span {
    color: var(--green-color);
}

.frMoveFridgeModal.frModalForm .frOnlyOneCheck {
    margin-left: 16px;
}

.frMoveFridgeModal .frQuantityControlBox {
    margin-bottom: 16px;
    border-bottom: 1px solid var(--background-color);
}

.frMoveFridgeModal .col-12 > label {
    margin-bottom: 0;
}

.frMoveFridgeModal .col-12:last-child .frQuantityControlBox {
    border-bottom: none;
}

.frsFridgeGuideModal .modal-lg {
    max-width: 850px;
}

.frsFridgeGuideModal .modal-body {
    max-height: 470px;
    overflow: auto;
}

.frsFridgeGuide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 5px;
    margin-left: -10px;
    margin-right: -10px;
}

.frsFridgeGuideCard {
    padding: 10px;
    border: 1px solid var(--light-gray-color);
    border-radius: 10px;
    margin: 10px;
}

.frsFridgeGuideCard > div:first-child {
    margin-bottom: 17px;
}

.frsFridgeGuideCard > div:first-child > span {
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(84, 58, 139, 0.2);
    padding: 2px 14px;
    border-radius: 5px;
}

.frsFridgeGuideCard > div:last-child {
    height: 133px;
    overflow: auto;
}



/* Pagination - react bootstrap table 2 */
.franchise .react-bootstrap-table td.react-bs-table-no-data {
    text-align: center;
}

.franchise .frTable ul {
    display: flex;
    align-items: center;
    justify-content: center;
    float: none;
    margin-top: 46px !important;
}

.franchise .frTable ul li {
    margin: 0 2px;
}

.franchise .frTable ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: var(--black-color);
    background-color: transparent;
    border: none;
    border-radius: 4px;
}

.franchise .frTable ul li.active a {
    color: #666;
    background-color: var(--more-light-gray-color);
}

.franchise .frTable ul li[title="previous page"] a,
.franchise .frTable ul li[title="next page"] a {
    visibility: hidden;
    line-height: 0;
    position: relative;
}

.franchise .frTable ul li[title="previous page"] a::before,
.franchise .frTable ul li[title="next page"] a::before {
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.franchise .frTable ul li[title="previous page"] a::before {
    content: url('/assets/img/icon/ic_arrow_left_dark.svg');
}

.franchise .frTable ul li[title="next page"] a::before {
    content: url('/assets/img/icon/ic_arrow_right_dark.svg');
}

.franchise .frTable ul li[title="first page"],
.franchise .frTable ul li[title="last page"] {
    display: none;
}

.franchise .frTable .frManagementBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.franchise .frTable .frManagementBox > div {
    padding: 0 10px;
}

.franchise .frTable .frManagementBox > div:first-child {
    padding-left: 0;
}

.franchise .frTable .frManagementBox > div:last-child {
    padding-right: 0;
}

.franchise .frTable .frManagementBox > div > span {
    font-weight: bold;
}

.franchise .frTable .frManagementBox > div:nth-child(2) > span {
    color: var(--black-color);
}

.franchise .frTable .frManagementBox > div:nth-child(2) > span {
    color: var(--imminent-color);
}

.franchise .frTable .frManagementBox > div:nth-child(3) > span {
    color: var(--expired-color);
}

.franchise .frTable .react-bootstrap-table th[data-row-selection] {
    width: 75px;
}

.franchise .frTable .frsFoodsExpandRow {
    display: flex;
}

.franchise .frTable .frsFoodsExpandRow > div {
    width: 17.5%;
    flex-grow: 1;
}

.franchise .frTable .frsFoodsExpandRow > div:nth-child(5) {
    width: 21.6%;
}

.franchise .frTable .frsFoodsExpandRow > div:last-child {
    width: calc(11.1% + 75px);
}

.franchise .frTable .frsFoodsExpandRow ul {
    display: block;
    margin-top: 0 !important;
}

.franchise .frTable .frsFoodsExpandRow ul > li {
    padding: 20px 10px;
    margin: 0;
}

.franchise .frTable .frsFoodsExpandRow > div:nth-child(3) ul > li,
.franchise .frTable .frsFoodsExpandRow > div:nth-child(4) ul > li {
    text-align: center;
}

.franchise .frTable .frsFoodsExpandRow > div:last-child ul > li {
    padding-right: 30px;
    text-align: right;
}

.franchise .frTable .frsFoodsExpandRow > div > ul > li > span {
    font-weight: bold;
}

.franchise .frTable .react-bootstrap-table .row-expansion-style {
    padding: 0;
}

.franchise .frTable .react-bootstrap-table-pagination {
    display: block;
    margin: 0;
}

.franchise .frTable .react-bootstrap-table-pagination > div:first-child {
    display: none;
}

.franchise .frTable .react-bootstrap-table-pagination > div:last-child {
    width: 100%;
    max-width: none;
}



/* Modal */
.modal {
    min-height: -webkit-fill-available;
}

.frModal .modal-dialog.modal-md {
    max-width: 600px;
}

.frModal .modal-dialog.modal-sm {
    max-width: 480px;
}

.frModal .modal-content {
    border: none;
    border-radius: 10px;
}

.frModal .modal-header {
    padding: 30px 30px 0;
    border-bottom: none;
}

.frModal .modal-content button[class="close"] {
    opacity: 1;
}

.frModal .modal-content button[class="close"] span {
    visibility: hidden;
    line-height: 0;
    position: relative;
}

.frModal .modal-content button[class="close"] span::before {
    content: url('/assets/img/icon/ic_close.svg');
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.frModalTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-color);
    line-height: 1.2;
}

.frModalTitleDesc {
    font-size: 14px;
    color: var(--gray-color);
    margin-top: 2px;
}

.frCenter {
    display: flex;
    justify-content: center;
}

.frRight {
    display: flex;
    justify-content: flex-end;
}

.frModal .modal-body {
    padding: 0 30px 30px;
}

.frModal ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.frModal .frModalList {
    margin-top: 16px;
    max-height: 500px;
    overflow: auto;
}

.frModal .frModalList li {
    display: flex;
    font-size: 16px;
    color: var(--black-color);
}

.frModal .frModalList li:first-child {
    font-weight: bold;
    background-color: var(--background-color);
    border-radius: 10px;
    margin-bottom: 3px;
}

.frModal .frModalList li > div {
    flex: 1 1 40%;
    text-align: center;
    padding: 10px;
}

.frMarkBox {
    display: inline-block;
    position: relative;
}

.frMarkBox img {
    position: absolute;
    top: 0;
    right: -30px;
}



/* Modal Form */
.frModalForm {
    margin-top: 20px;
}

.frModalForm label > span {
    color: var(--green-color);
}

.frModalForm .form-check {
    margin-top: -10px;
    margin-bottom: 1rem;
}

.frModalForm .frOnlyOneCheck {
    margin: 24px 0 22px;
}

.frModalForm .form-control {
    color: var(--black-color);
    border-color: var(--more-light-gray-color);
}

.frModalForm .form-control.is-invalid {
    background-image: none;
    border-color: var(--expired-color);
}

.frModalForm .form-control::placeholder {
    color: var(--light-gray-color);
}

.frModalForm .form-control:focus {
    border-color: var(--green-color);
    box-shadow: 0 0 0 0.2rem rgb(2 82 42 / 25%);
}

.frModalForm .form-control:disabled,
.frModalForm .form-control[readonly] {
    color: var(--gray-color);
    background-color: var(--more-light-gray-color);
}

.frModal .todoCreate .col-12 {
    margin-bottom: 24px;
}

.frModal .todoCreate .col-12 > div:nth-child(2) {
    margin-bottom: 0;
}

.frModal .todoCreate textarea.form-control {
    height: 100px;
}

.frModal .todoWord {
    font-size: 14px;
    color: var(--light-gray-color);
    text-align: right;
    margin-top: 4px;
}

.frPower {
    margin-bottom: 24px;
}

.frPower select {
    width: 140px;
}

.frModalDetail .col-12 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.frModalDetailAlignStart .col-12 {
    align-items: flex-start;
}

.frModalDetail .col-12 > div:nth-child(1) {
    width: 100px;
    flex-shrink: 0;
}

.frModalDetail .col-12 > div:nth-child(2) {
    flex-grow: 1;
}

.frModalDetail .frGrayButton {
    font-size: 14px;
    padding: 4px 12px;
    margin-left: 18px;
}

.frModalDetail + .frRight {
    margin-top: 10px;
}

.frModalDetail + .frRight > button:last-child {
    margin-left: 16px;
}

.frAddFoodsModal {
    font-size: 16px;
}

.frAddFoodsModal .form-check {
    margin: 0;
}

.frAddFoodsHead {
    font-weight: bold;
    background-color: var(--background-color);
    border-radius: 10px;
}

.frAddFoodsHead,
.frAddFoodsModal ul li {
    display: flex;
    align-items: center;
}

.frAddFoodsHead > div,
.frAddFoodsModal ul li > div {
    padding: 13px 10px;
}

.frAddFoodsHead > div:last-child(3) {
    display: flex;
    align-items: center;
}

.frAddFoodsHead > div:last-child(3) > img {
    margin-left: 4px;
}

.frAddFoodsModal ul {
    margin: 10px 0;
}

.frAddFoodsModal ul li {
    margin: 10px 0;
}

.frAddFoodsHead > div:nth-child(1),
.frAddFoodsModal ul li > div:nth-child(1) {
    width: 10%;
}

.frAddFoodsHead > div:nth-child(2),
.frAddFoodsModal ul li > div:nth-child(2) {
    width: 35%;
}

.frAddFoodsModal ul li > div:nth-child(2) > div:last-child {
    font-size: 12px;
    color: var(--expired-color);
}

.frAddFoodsHead > div:nth-child(3),
.frAddFoodsModal ul li > div:nth-child(3) {
    width: 15%;
    text-align: center;
}

.frAddFoodsHead > div:nth-child(4),
.frAddFoodsModal ul li > div:nth-child(4) {
    width: 40%;
}

.frAddFoodsModal ul li > div:nth-child(4) > .form-group {
    margin-bottom: 0;
}

.frAddFoodsModal ul li > div:nth-child(3) > div {
    margin-bottom: 0;
}

.frCameraInput {
    display: none;
}

.frCameraLabel {
    visibility: hidden;
    line-height: 0;
    position: relative;
}

.frCameraLabel::before {
    content: '촬영';
    display: block;
    width: 112px;
    height: 42px;
    text-align: center;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    padding: 20px 0;
    border: 1px solid var(--more-light-gray-color);
    border-radius: 4px;
    visibility: visible;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.active + .frCameraLabel::before {
    content: '촬영 완료';
    color: var(--green-color);
    border-color: var(--green-color);
}

.addPopExpMsg {
    color: #666;
}

.frWelcomeModal {
    pointer-events: none;
}

.frOrderRequest {
    overflow-y: auto;
    margin-bottom: 10px;
}

.frOrderRequest .frOrderList {
    max-height: 400px;
}

.frOrderRequest + .frCenter {
    padding-top: 16px;
}

.frOrderFoods > li {
    margin-bottom: 20px;
}

.frOrderFoods > li > div:first-child {
    background-color: rgba(4,128,66,.1);
    font-size: 16px;
    font-weight: bold;
    padding: 5px 10px;
}

.frOrderFoods > li > div:nth-child(2) {
    display: flex;
    text-align: center;
    background-color: var(--background-color);
}

.frOrderFoods > li > div:nth-child(2) > div {
    flex: 1 1 0;
    padding: 5px 10px;
}

.frOrderFoods ul > li {
    display: flex;
    text-align: center;
}

.frOrderFoods ul > li > div {
    flex: 1 1 0;
    padding: 5px 10px;
}

.frOrderClients {
    border-top: 1px solid var(--light-gray-color);
    border-bottom: 1px solid var(--light-gray-color);
    margin-top: 40px;
    margin-bottom: 10px !important;
}

.frOrderClients li {
    display: flex;
    padding: 5px 10px;
}

.frOrderClients li > div {
    width: 20%;
}

.frOrderClients li > div:nth-child(2),
.frOrderClients li > div:nth-child(4),
.frOrderClients li > div:nth-child(5) {
    text-align: right;
}

.frOrderTotal {
    display: flex;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 20px;
}

.frOrderTotal > div {
    flex: 1 1 0;
}

.frOrderTotal > div:last-child {
    text-align: right;
}

.frOrderDesc {
    padding: 0 10px;
}

.frOrderDesc > div:first-child {
    font-weight: bold;
}

.frMoveFoodsReceive .form-check{
    margin: 0;
}

.frMoveFoodsHeader {
    font-size: 16px;
    font-weight: bold;
    background-color: var(--background-color);
    border-radius: 10px;
}

.frMoveFoodsHeader,
.frMoveFoodsBody ul li {
    display: flex;
    align-items: center;
    text-align: center;
}

.frMoveFoodsHeader > div,
.frMoveFoodsBody ul li > div {
    flex: 1 1 30%;
    padding: 13px 10px;
}

.frMoveFoods + .frCenter {
    padding-top: 16px;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div,
.frMoveFoodsReceive .frMoveFoodsBody ul li > div {
    flex: auto;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(1),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(1) {
    width: 10%;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(2),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(2) {
    width: 18%;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(3),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(3) {
    width: 18%;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(4),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(4) {
    width: 15%;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(5),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(5) {
    width: 15%;
}

.frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(6),
.frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(6) {
    width: 24%;
}

.frTotalFoods .frMoveFoodsHeader,
.frTotalFoods .frMoveFoodsBody ul li {
    flex-wrap: nowrap;
}

.frTotalFoods .frMoveFoodsHeader > div {
    word-break: keep-all;
}

.frTotalFoods .frMoveFoodsHeader > div,
.frTotalFoods .frMoveFoodsBody ul li > div {
    flex: none;
    width: 25%;
}

.frTotalFoods .frMoveFoodsBody ul li > div:last-child > span {
    width: calc(100% - 10px);
    display: inline-block;
    background-color: var(--safety-color);
    font-weight: bold;
    color: var(--white-color);
    padding: 7px;
    border-radius: 10px;
}

.frTotalFoods .emptyText {
    justify-content: center;
    padding: 20px;
}



.frOpenNewTabLink {
    display: flex;
    color: var(--black-color);
    cursor: pointer;
    word-break: break-all;
}

.frOpenNewTabLink:hover,
.frOpenNewTabLink:focus,
.frOpenNewTabLink:active {
    color: var(--black-color);
    text-decoration: underline;
    opacity: .8;
}

.frOpenNewTabLink img {
    transform: scale(0.8);
}

/* Login */
.frLogin {
    background-color: var(--background-color);
    justify-content: center;
}

.frLoginBox {
    width: 500px;
    background-color: var(--white-color);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    padding: 60px;
}

.frLoginTitle {
    text-align: center;
}

.frLoginTitle img {
    width: 120px;
    margin-bottom: 10px;
}

.frLoginDesc {
    font-size: 14px;
    color: var(--gray-color);
}

.frLogin .frGreenButton {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 8px;
    margin-bottom: 16px;
}

.frLogin .frGreenButton:disabled {
    color: var(--white-color);
    opacity: 1;
}

.frLogin .frGreenButton:disabled:hover {
    opacity: .8;
}

.frLogin .channelTalk {
    font-size: 14px;
    color: var(--gray-color);
    text-decoration: underline;
}

.frLogin .channelTalk:hover,
.frLogin .channelTalk:focus,
.frLogin .channelTalk:active {
    color: var(--gray-color);
}



/* Pagination */
.franchise .frPagination {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.franchise .frPagination ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.franchise .frPagination li {
    margin: 0 2px;
}

.franchise .frPagination button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: var(--black-color);
    background-color: transparent;
    border: none;
    border-radius: 4px;
}

.franchise .frPagination .page-item.disabled .page-link {
    background-color: transparent;
}

.franchise .frPagination li.active button {
    color: #666;
    background-color: var(--more-light-gray-color);
}

.franchise .frPagination button[aria-label="Previous"] span,
.franchise .frPagination button[aria-label="Next"] span {
    display: none;
}

.franchise .frPagination button[aria-label="Previous"]::before {
    content: url('/assets/img/icon/ic_arrow_left_dark.svg');
}

.franchise .frPagination button[aria-label="Next"]::before {
    content: url('/assets/img/icon/ic_arrow_right_dark.svg');
}

.franchise .frPagination li.disabled button[aria-label="Previous"]::before {
    content: url('/assets/img/icon/ic_arrow_left_grey.svg');
}

.franchise .frPagination li.disabled button[aria-label="Next"]::before {
    content: url('/assets/img/icon/ic_arrow_right_grey.svg');
}



/* sidebar */
.frSidebar {
    width: 220px;
    height: calc(100vh - 60px);
    font-size: 14px;
    font-weight: bold;
    color: var(--white-color);
    background-color: var(--green-color);
    padding-top: 20px;
    box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.16);
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 801;
}

.frSideLogout {
    display: inline-block;
    color: var(--white-color);
    font-size: 14px;
    background-color: #484848;
    padding: 6px 19px;
    border-radius: 11px;
    margin-top: 20px;
}

.frSideLogout:hover,
.frSideLogout:focus,
.frSideLogout:focus-visible,
.frSideLogout:active {
    color: var(--white-color);
    opacity: .8;
}

.frSideProfile {
    height: 280px;
    text-align: center;
    padding: 50px 0 30px;
}

.frSideImgBox {
    width: 140px;
    border-radius: 140px;
    margin: 0 auto 16px;
}

.frSideImgBox > img {
    width: 100%;
}

.frSideInfo > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

.frSideInfo > div:first-child > img {
    margin-left: 2px;
}

.frSideNav {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.frSidebar-small .frSideNav {
    overflow: unset;
}

.frSidebar-small .frSideParent.menuClick .frSideChild {
    display: none;
}

.frSidebar-small .frSideParent:hover .frSideChild {
    display: block;
}

.frSideNav li {
    border-radius: 8px;
    margin: 2.5px 8px;
}

/* .frSideNav > li:last-child {
    margin-top: 20px;
    position: relative;
}

.frSideNav > li:last-child::before {
    content: '';
    display: block;
    width: calc(100% + 16px);
    height: 1px;
    background-color: var(--white-color);
    position: absolute;
    top: -10px;
    left: -8px;
} */

.frSideNav li a,
.frSideParentName {
    display: flex;
    height: 45px;
    align-items: flex-start;
    justify-content: center;
    color: var(--white-color);
    padding: 10px 0;
    cursor: pointer;
}

.frSideNav li:hover,
.frSideParent:hover {
    background-color: var(--light-green-color) !important;
}

.frSideNav li a:hover,
.frSideParentName:hover {
    color: var(--white-color) !important;
}

/* .frSideNav li a,
.frSideParentName,
.frSideParent .frSideChild a {
    border-left: 6px solid var(--black-color);
} */

.frSideParent {
    position: relative;
}

.frSideNav > li.active,
.frSideParent.active {
    background-color: var(--orange-color) !important;
}

/* .frSideNav > li.active a > .frSideName,
.frSideParent.active .frSideParentName div {
    font-weight: 500;
    color: var(--green-color);
} */

/* .frSideParent.active li a {
    border-left: 6px solid var(--white-color);
} */

/* .frSideNav > li.active a,
.frSideParent.active {
    background-color: var(--orange-color) !important;
} */

.frSideParent.menuClick,
.frSideParent.menuClick li {
    background-color: var(--light-green-color);
}

/* .frSideParent.menuClick .frSideParentName,
.frSideParent.menuClick li a {
    border-left: 6px solid #484848;
} */

/* .frSideParent .frSideParentName:hover {
    border-left: 6px solid #484848;
} */

/* .frSideParent.active .frSideParentName,
.frSideParent.active .frSideChild li a {
    background-color: var(--orange-color) !important;
} */

/* .frSideParent.active .frSideChild li.active a,
.frSideChild li.active a {
    color: var(--green-color) !important;
} */

/* .frSideParent.active .frSideParentName:hover,
.frSideParent.active .frSideParentName {
    border-left: 6px solid var(--green-color);
} */

.frSideChild li {
    margin-right: 0;
    margin-left: 0;
}

.frSideChild li:hover {
    background-color: var(--more-light-green-color) !important;
}

.frSideChild li.active a,
.frSideChild li a:hover {
    color: var(--black-color);
}

.frSideParent.active .frSideChild li {
    background-color: var(--orange-color) !important;
}

.frSideParent.active .frSideChild li.active,
.frSideParent.active .frSideChild li:hover {
    background-color: var(--dark-orange-color) !important;
}

.frSideDropDown {
    width: 24px;
    text-align: right;
}

.frSideParent.menuClick .frSideDropDown > img {
    transform: rotate(180deg);
}

.frSideChild {
    display: none;
}

.frSideParent.menuClick,
.frSideParent.active {
    padding-bottom: 0;
}

.frSideParent.menuClick .frSideChild {
    display: block;
}

.frSideParent .frSideChild a {
    padding-right: 24px;
    padding-left: 44px;
}

.frSideParent.menuClick .frSideChild a {
    color: var(--white-color);
}

/* .frSideParent.active .frSideChild a {
    color: var(--black-color);
} */

.frSideIcon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    flex-shrink: 0;
    margin: 0 10px;
}

.frSideName {
    flex-grow: 1;
    text-align: center;
}

/* .frSideNav > li.active .frSideIcon,
.frSideNav > li > a:hover .frSideIcon,
.frSideNav .frSideParentName:hover .frSideIcon {
    filter: invert(26%) sepia(36%) saturate(616%) hue-rotate(99deg) brightness(97%) contrast(107%);
} */

.frSideTerms {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 0;
}

.frSideTerms a {
    font-size: 14px;
    color: var(--light-gray-color);
    text-decoration: underline;
}

.frSideTerms a:first-child {
    margin-right: 10px;
}

.frSideTerms a:hover,
.frSideTerms a:focus,
.frSideTerms a:active {
    color: var(--white-color);
    text-decoration: underline;
}

.frSidebarBtn {
    position: absolute;
    top: 40px;
    right: -20px;
}

.frSidebarBtn button {
    width: 40px;
    height: 40px;
    background-image: url(/assets/img/icon/ic_menu_close.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    box-shadow: none;
    background-color: transparent;
}

.frMobileSideBtn {
    width: 32px;
    height: 32px;
    background-image: url(/assets/img/icon/btn_menu.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    margin-left: 20px;
}

.frSidebar-small .frSidebar {
    width: 60px;
}

.frSidebar-small .frSideProfile,
.frSidebar-small .frSideName,
.frSidebar-small .frSideParent .frSideDropDown > img,
.frSidebar-small .frSideTerms {
    display: none;
}

.frSidebar-small .frSideNav {
    padding-top: 280px;
}

.frSidebar-small .frSideParent {
    position: relative;
}

.frSidebar-small .frSideParent .frSideChild {
    width: 15vw;
    position: absolute;
    top: 0;
    left: 60px;
    box-shadow: 3px 0 8px 0 rgb(0 0 0 / 16%);
}

.frSidebar-small .frSidebarBtn {
    transform: rotate(180deg);
}

.frSideDisplay {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .3;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}



/* FullCalendar */
.frCalendar .fc-theme-standard .fc-scrollgrid {
    border: none;
}

.frCalendar .fc-theme-standard td,
.frCalendar .fc-theme-standard th {
    border: none;
    border-radius: 8px;
}

.frCalendar .fc .fc-view-harness {
    height: 380px !important;
}

.frCalendar .fc .fc-scroller,
.frCalendar .fc .fc-scroller-liquid-absolute {
    overflow: auto !important;
}

.frCalendar .fc-daygrid-dot-event {
    justify-content: center;
}

.frCalendar .fc-daygrid-event-dot {
    width: 12px;
    height: 7px;
    background-color: var(--green-color);
    border: none;
}

.frCalendar .fc-direction-ltr .fc-daygrid-event .fc-event-time,
.frCalendar .fc-daygrid-dot-event .fc-event-title {
    display: none;
}

.frCalendar .fc .fc-today-button {
    display: none;
}

.frCalendar .fc-header-toolbar {
    width: 100%;
    height: 36px;
    position: relative;
}

.frCalendar .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(1)  {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.frCalendar .fc-header-toolbar .fc-toolbar-title {
    color: var(--black-color);
    font-size: 16px;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
    display: block;
    width: calc(100% - 40px);
    margin: 0 auto;
    position: relative;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group > button {
    position: absolute;
    top: 0;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group > button.fc-prev-button {
    left: 0;
}

.frCalendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group > button.fc-next-button {
    right: 0;
}

.frCalendar .fc-header-toolbar .fc-button-group > button {
    width: 24px;
    height: 24px;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
}

.frCalendar .fc-header-toolbar .fc-button-group > button:hover,
.frCalendar .fc-header-toolbar .fc-button-group > button:focus,
.frCalendar .fc-header-toolbar .fc-button-group > button:focus-visible,
.frCalendar .fc-header-toolbar .fc-button-group > button:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.frCalendar .fc-header-toolbar .fc-button-group > button > span {
    color: var(--gray-color);
}

.frCalendar .fc .fc-daygrid-day-top {
    justify-content: center;
}

.frCalendar .activeDate {
    background-color: var(--green-color) !important;
    color: var(--white-color);
}

.frCalendar .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.frCalendar .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    width: 100%;
    height: 60px;
    position: absolute;
    top: -30px;
    left: 0;
    background: transparent;
    box-shadow: none;
}

.frCalendar .fc-event-selected:after,
.frCalendar .fc-event:focus:after {
    background: transparent;
}

.frDateRangeCon {
    display: flex;
    align-items: center;
}

.frDateRangeBox {
    width: 210px;
    height: calc(1.5em + .75rem + 2px);
    padding: 5px;
    border: 1px solid var(--more-light-gray-color);
    border-radius: 4px;
    margin-right: 5px;
    overflow: hidden;
}

.frDateRangeBox input {
    width: 100%;
    text-align: center;
    border: none;
}

.frDateRangeCon button {
    height: calc(1.5em + .75rem + 2px);
    font-size: 14px;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    padding: 0 !important;
}

.frQuantityControlBox {
    display: flex;
    align-items: center;
}

.frQuantityControl {
    display: flex;
    align-items: center;
    padding: 3px 6px;
    border: 1px solid var(--more-light-gray-color);
    border-radius: 9px;
}

.frQuantityControl input {
    width: 74px;
    text-align: center;
    border: none;
    margin: 0 5px;
}

.twitter {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

.frQuantityControl input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.frQuantityControl button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
}

.frQuantityControl button.disabled {
    opacity: .5;
    cursor: auto;
}

.frQuantityControlBox.disabled,
.frQuantityControl.disabled {
    opacity: .5;
}

.frQuantityControlBox.disabled button,
.frQuantityControl.disabled button {
    pointer-events: none;
}

.frQuantityControl input.form-control:disabled {
    color: var(--black-color);
    background-color: var(--white-color);
}

.frQuantityCalc {
    display: none;
    color: var(--green-color);
    font-size: 16px;
    margin-left: 10px;
}

.frQuantityCalc.show {
    display: block;
}



/* Error Page(404, 500, 만료) */
.frErrorPage {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--white-color);
    position: relative;
}

.frErrorPageLogo {
    width: 218px;
    position: absolute;
    top: 50px;
    left: 50px;
}

.frErrorPageLogo img {
    width: 100%;
}

.frErrorCon {
    text-align: center;
    margin: 0 auto;
}

.frErrorTitle {
    font-size: 26px;
    font-weight: bold;
    color: var(--black-color);
    margin: 30px 0 16px 0;
}

.frErrorDesc {
    font-size: 16px;
    color: var(--gray-color);
}

.frErrorCon > button {
    width: 458px;
    font-size: 16px;
    color: var(--white-color);
    background-color: var(--black-color);
    padding: 12px;
    border-color: var(--black-color);
    border-radius: 4px;
    margin-top: 30px;
}

.frErrorCon > button:hover,
.frErrorCon > button:focus,
.frErrorCon > button:active,
.frErrorCon > button:focus-visible {
    color: var(--white-color);
    background-color: var(--black-color);
    border-color: var(--black-color);
    opacity: .8;
}

.frErrorChat {
    text-align: left;
    margin-top: 16px;
}

.frErrorChat a {
    font-size: 14px;
    color: var(--gray-color);
    text-decoration: underline;
}

.frErrorChat a:hover,
.frErrorChat a:focus,
.frErrorChat a:active {
    color: var(--gray-color);
    text-decoration: underline;
}

.frExpInput {
    display: flex;
    align-items: center;
}

.frExpInput .form-group {
    margin-bottom: 0;
}

.frExpInput input {
    width: 80px;
    margin: 0 8px;
}

.svDetail.frModalDetail .col-12 > div:nth-child(1) {
    color: var(--gray-color);
}

.noticeDetail {
    min-height: 200px;
}

.frHygieneAuto {
    border: none;
    margin-bottom: 16px !important;
}

.frHygieneAuto input {
    background-image: url('/assets/img/icon/ic_search.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px;
    padding-left: 41px;
}

.freeChargeBox {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 1rem;
}

.freeChargeBox > div {
    flex-grow: 1;
    margin-bottom: 0;
}

.freeChargeBox > span {
    width: 110px;
    margin-left: 10px;
}

.frClientSearchAuto.ant-select {
    border: none;
    margin-bottom: 1rem;
}

.frFilterBox {
    display: flex;
    margin-bottom: 16px;
}

.frFilter input {
    width: 344px;
    height: 44px;
    background-image: url('/assets/img/icon/ic_search.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 41px;
    border-color: var(--more-light-gray-color);
}

.frFilter input::placeholder {
    color: var(--light-gray-color);
}

.frFilter > label {
    margin-bottom: 0;
}

.frTopButton {
    display: inline-block;
    background-color: var(--gray-color);
    color: var(--white-color);
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    position: fixed;
    right: 30px;
    bottom: 70px;
    opacity: 0.8;
    z-index: 1;
}

.frTopButton:hover,
.frTopButton:focus,
.frTopButton:active {
    opacity: 0.5;
}










/* PC, Tablet 중간 사이즈 */
@media (min-width: 1025px) and (max-width: 1700px) {
    .franchise .frTable .frsFoodsExpandRow > div:nth-child(1),
    .franchise .frTable .frsFoodsExpandRow > div:nth-child(2),
    .franchise .frTable .frsFoodsExpandRow > div:nth-child(3) {
        display: none;
    }
}










/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
    .franchise input,
    .franchise textarea,
    .franchise select,
    .frLogin input,
    .frLogin textarea,
    .frLogin select {
        width: 114.28%;
        font-size: 16px;
        transform: scale(0.875);
        transform-origin: left top;
    }

    /* Header */
    .frSearchAutoBox {
        padding: 0 8px 8px 8px;
    }
    
    .frSearchAutoBox > div {
        width: 100%;
    }

    .franchise .app-header {
        justify-content: space-between;
        height: 55px;
    }

    .franchise .app-header .navbar-toggler {
        min-width: 60px;
    }

    .franchise .app-header .navbar-brand {
        width: 130px;
        height: auto;
        margin-left: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Body */
    .franchise .app-body {
        margin-top: 55px;
    }

    .franchise .main .container-fluid {
        padding: 30px 40px 0;
    }


    /* sidebar */
    .frSidebar-small .frSidebar,
    .frSidebar {
        display: none;
        width: 220px;
        height: calc(100vh - 55px);
        font-size: 14px;
        top: 55px;
    }

    .frSidebarMobile-show,
    .frSearchMobile-show {
        overflow: hidden;
    }

    .frSidebarMobile-show .frSidebar {
        display: block;
    }

    .frSidebarMobile-show .frSideDisplay,
    .frSearchMobile-show .frSideDisplay,
    .frHeaderInfoMobile-show .frSideDisplay {
        display: block;
    }

    

    .franchise .main,
    .franchise .app-footer,
    .frSidebar-small .franchise .main,
    .frSidebar-small .franchise .app-footer {
        margin-left: 0;
    }

    .frSideProfile {
        padding: 30px 0 20px;
    }

    .frSideImgBox {
        width: 90px;
    }

    .frSideNav {
        max-height: calc(100% - 256px);
    }

    .frSideNav li a,
    .frSideParentName {
        height: auto;
        padding: 7px 0;
    }

    .frSideParent img {
        top: 13px;
        right: 10px;
    }

    .frSideIcon {
        margin-left: 14px;
    }

    .frSideParent.active .frSideChild a {
        padding-left: 48px;
    }

    .frSideTerms {
        bottom: 20px;
    }

    .frSidebarBtn {
        display: none;
    }
    


    /* Header Search Mobile Show/hide */
    .frSearchAutoBox.frSearchMobile-show > div > div {
        display: block;
    }

    body.frSearchMobile-show .ant-select-dropdown {
        position: fixed !important;
        top: 101px !important;
        left: 0 !important;
        border-radius: 10px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.14);
    }

    body.frSearchMobile-show .ant-select-item {
        color: #666;
    }

    body.frSearchMobile-show .ant-select-clear {
        display: none;
    }

    .frSidebar-small .frSideNav {
        padding-top: 0;
    }

    .frSidebar-small .frSideProfile,
    .frSidebar-small .frSideName,
    .frSidebar-small .frSideParent img,
    .frSidebar-small .frSideTerms {
        display: block;
    }

    .frSidebar-small .frSideParent .frSideDropDown > img {
        display: inline;
    }

    .frSidebar-small .frSideParent.menuClick .frSideChild {
        display: block;
    }

    .frSidebar-small .frSideParent .frSideChild {
        width: 100%;
        position: relative;
        left: 0;
        box-shadow: none;
    }

    .frHeaderInfo.frHeaderInfoMobile-show > div:last-child {
        display: block;
    }


    .franchise .frTable .selection-cell-header::after,
    .franchise .frTable .selection-cell::after {
        left: 20px;
    }

    .franchise .frTable .frsFoodsExpandRow > div {
        width: 45%;
    }

    .franchise .frTable .frsFoodsExpandRow > div:first-child {
        width: 10%;
    }

    .frFoodsTablet tr th:nth-child(1) {
        width: 22%;
    }

    .frFoodsTablet tr th:nth-child(2) {
        width: 17%;
    }

    .frFoodsTablet tr th:nth-child(3) {
        width: 15%;
    }


    
    /* Modal */
    .frsFridgeGuideModal .modal-lg {
        max-width: 500px;
    }
    
    .frsFridgeGuide {
        grid-template-columns: 1fr 1fr;
    }
}










/* Mobile */
@media (max-width:767px) {
    .franchise input,
    .franchise textarea,
    .franchise select,
    .frLogin input,
    .frLogin textarea,
    .frLogin select {
        width: 114.28%;
        font-size: 16px;
        transform: scale(0.875);
        transform-origin: left top;
    }

    .frGWButton,
    .frGreenButton,
    .frWhiteButton,
    .frBlackButton,
    .frGrayButton {
        font-size: 14px;
        padding: 3px 11px;
    }

    .frGreenButton > img {
        width: 18px;
    }

    /* 로그인 */
    .frLogin {
        padding: 20px;
    }

    .frLoginBox {
        width: 100%;
        padding: 60px 40px;
    }

    .frLoginTitle img {
        width: 130px;
        margin-bottom: 5px;
    }

    .frLoginDesc {
        font-size: 12px;
    }

    .frLogin .frGreenButton {
        margin-bottom: 10px;
    }



    /* Header */
    .frSearchAutoBox {
        padding: 0 8px 8px 8px;
    }
    
    .frSearchAutoBox > div {
        width: 100%;
    }
    
    .franchise .app-header {
        justify-content: space-between;
        height: 55px;
    }

    .franchise .app-header .navbar-toggler {
        min-width: 60px;
    }

    .franchise .app-header .navbar-brand {
        width: 130px;
        height: auto;
        margin-left: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Footer */
    .franchise .app-footer {
        padding: 15px 30px;
    }

    .frFooter {
        display: block;
    }

    .frFooter > div:first-child {
        margin-bottom: 3px;
    }

    .frFooter > div:last-child {
        text-align: left;
    }



    /* Body */
    .franchise .app-body {
        margin-top: 55px;
    }

    .franchise .main .container-fluid {
        padding: 15px 20px 0;
    }



    /* Modal */
    .frModal .modal-dialog {
        max-width: none !important;
        margin: 20px;
    }

    .frModalTitle {
        font-size: 18px;
        line-height: 1.5;
    }
    
    .frModalTitleDesc {
        font-size: 14px;
        color: var(--gray-color);
        margin-top: 2px;
    }

    .frModal .frModalList li {
        font-size: 14px;
    }

    .frModal .frModalList li:first-child {
        margin-bottom: 10px;
    }

    .frModal .frModalList li > div {
        padding: 10px 4px;
    }
    
    .welcomeModal .col-12 {
        font-size: 14px;
    }
    
    .welcomeModal .col-12 > div:nth-child(2) > p {
        font-size: 12px;
    }

    .frAddFoodsModal {
        font-size: 14px;
    }

    .frAddFoodsHead > div {
        padding: 15px 5px;
    }

    .frAddFoodsModal ul li > div {
        padding: 5px;
    }

    .frAddFoodsModal .form-check {
        transform: scale(0.7);
    }

    .frAddFoodsHead > div:nth-child(1),
    .frAddFoodsModal ul li > div:nth-child(1) {
        width: 18%;
    }

    .frAddFoodsHead > div:nth-child(2),
    .frAddFoodsModal ul li > div:nth-child(2) {
        width: 32%;
    }

    .frAddFoodsHead > div:nth-child(3),
    .frAddFoodsModal ul li > div:nth-child(3) {
        width: 17%;
        text-align: center;
    }

    .frAddFoodsHead > div:nth-child(4),
    .frAddFoodsModal ul li > div:nth-child(4) {
        width: 33%;
    }
    
    .frCameraLabel::before {
        width: 66px;
        height: 30px;
        padding: 14px 0;
    }

    .frOrderRequest .frOrderList {
        max-height: 300px;
    }

    .frOrderDate {
        font-size: 14px;
    }

    .frOrderFoods > li > div:first-child {
        font-size: 14px;
    }

    .frOrderFoods > li > div:nth-child(2) {
        font-size: 12px;
    }
    
    .frOrderFoods > li > div:nth-child(2) > div {
        padding: 5px 0px 5px 10px;
    }

    .frMoveFoodsReceive .frMoveFoodsBody ul li {
        border-bottom: 1px solid var(--background-color);
    }

    .frMoveFoodsReceive .frMoveFoodsBody ul li:last-child {
        border-bottom: none;
    }

    .frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(1),
    .frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(1) {
        width: 60px;
        flex: none;
        flex-shrink: 0;
    }

    .frMoveFoodsReceive .frMoveFoodsHeader > div:nth-child(2),
    .frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(2) {
        width: auto;
        flex-grow: 1;
        text-align: left;
    }

    .frMoveFoodsReceive .frMoveFoodsBody ul li > div:nth-child(2) > div > span {
        font-weight: bold;
    }

    .frMoveFoodsHeader {
        font-size: 14px;
    }

    .frTotalFoods .frMoveFoodsBody ul li > div:last-child {
        padding-right: 0;
        padding-left: 0;
    }

    .frTotalFoods .frMoveFoodsBody ul li > div:last-child > span {
        width: 100%;
    }

    .frsFridgeGuideModal .modal-lg {
        max-width: none;
    }
    
    .frsFridgeGuide {
        grid-template-columns: 1fr;
    }



    /* Button */
    /* .frGWButton,
    .frGreenButton,
    .frWhiteButton {
        font-size: 14px;
        padding: 4px 10px;
    }

    .frGWButton img,
    .frGreenButton img,
    .frWhiteButton img {
        width: 18px;
    } */

    .frGreenButton.frFullButton {
        font-size: 16px;
        padding: 8px;
    }

    .frGreenButton.frFullButton img {
        width: 30px;
    }

    .frGWButton {
        color: var(--green-color);
        background-color: var(--white-color);
        border: 1px solid var(--green-color);
    }

    .frGWButton:hover,
    .frGWButton:focus,
    .frGWButton:active {
        color: var(--green-color);
        opacity: .8;
    }

    .frGreenButton {
        color: var(--white-color);
        background-color: var(--green-color) !important;
        border: 1px solid var(--green-color) !important;
    }

    .frGreenButton:hover,
    .frGreenButton:focus,
    .frGreenButton:active {
        color: var(--white-color);
        opacity: .8;
    }

    .frWhiteButton {
        color: #666;
        background-color: var(--white-color);
        border: 1px solid var(--more-light-gray-color);
    }

    .frWhiteButton:hover,
    .frWhiteButton:focus,
    .frWhiteButton:active {
        color: #666;
        background-color: var(--more-light-gray-color);
    }



    /* Table - react bootstrap table 2 */
    .franchise .frTable {
        margin-right: -20px;
        margin-left: -20px;
    }

    .franchise .frTable tr th:first-child,
    .franchise .frTable tr td:first-child {
        padding-left: 13px;
    }

    .franchise .frTable tr th:last-child,
    .franchise .frTable tr td:last-child {
        padding-right: 20px;
    }

    .franchise .frTable tr th,
    .franchise .frTable tr td {
        word-break: break-all;
    }

    .franchise .frTable .selection-cell-header::after,
    .franchise .frTable .selection-cell::after {
        left: 20px;
    }

    .franchise .frTable .frsFoodsExpandRow > div:nth-child(1) {
        width: 65%;
        text-align: left;
    }

    .franchise .frTable .frsFoodsExpandRow > div:nth-child(2) {
        width: 35%;
    }

    .franchise .frTable .frsFoodsExpandRow > div:nth-child(1) > ul > li {
        padding-right: 5px;
        padding-left: 10px;
    }

    .franchise .frTable .frsFoodsExpandRow > div:nth-child(2) > ul > li {
        padding-right: 10px;
        padding-left: 5px;
    }

    .franchise .frTable .react-bootstrap-table .reset-expansion-style {
        padding: 0;
    }

    .franchise .frTable .frsFoodsExpandRow ul > li {
        height: 84px;
    }



    /* sidebar */
    .frSidebar-small .frSidebar,
    .frSidebar {
        display: none;
        width: 220px;
        height: calc(100vh - 55px);
        font-size: 14px;
        top: 55px;
    }

    .frSidebarMobile-show,
    .frSearchMobile-show {
        overflow: hidden;
    }

    .frSidebarMobile-show .frSidebar {
        display: block;
    }

    .frSidebarMobile-show .frSideDisplay,
    .frSearchMobile-show .frSideDisplay,
    .frHeaderInfoMobile-show .frSideDisplay {
        display: block;
    }

    

    .franchise .main,
    .franchise .app-footer,
    .frSidebar-small .franchise .main,
    .frSidebar-small .franchise .app-footer {
        margin-left: 0;
    }

    .frSideProfile {
        padding: 30px 0 20px;
    }

    .frSideImgBox {
        width: 90px;
    }

    .frSideNav {
        max-height: calc(100% - 256px);
    }

    .frSideNav li a,
    .frSideParentName {
        height: auto;
        padding: 7px 0;
    }

    .frSideParent img {
        top: 8px;
        right: 10px;
    }

    .frSideIcon {
        margin-left: 14px;
    }

    .frSideParent.active .frSideChild a {
        padding-left: 48px;
    }

    .frSideTerms {
        bottom: 20px;
    }

    .frSidebarBtn {
        display: none;
    }
    


    /* Header Search Mobile Show/hide */
    .frSearchAutoBox.frSearchMobile-show > div > div {
        display: block;
    }

    body.frSearchMobile-show .ant-select-dropdown {
        position: fixed !important;
        top: 101px !important;
        left: 0 !important;
        border-radius: 10px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.14);
    }

    body.frSearchMobile-show .ant-select-item {
        color: #666;
    }

    body.frSearchMobile-show .ant-select-clear {
        display: none;
    }

    .frSidebar-small .frSideNav {
        padding-top: 0;
    }

    .frSidebar-small .frSideProfile,
    .frSidebar-small .frSideName,
    .frSidebar-small .frSideParent img,
    .frSidebar-small .frSideTerms {
        display: block;
    }

    .frSidebar-small .frSideParent .frSideDropDown > img {
        display: inline;
    }

    .frSidebar-small .frSideParent.menuClick .frSideChild {
        display: block;
    }

    .frSidebar-small .frSideParent .frSideChild {
        width: 100%;
        position: relative;
        left: 0;
        box-shadow: none;
    }

    .frHeaderInfo.frHeaderInfoMobile-show > div:last-child {
        display: block;
    }


    .frModalForm .frTodoList {
        min-height: 200px;
        max-height: 300px;
    }

    .frModal .frModalList {
        max-height: 300px;
    }

    .frTopButton {
        font-size: 14px;
        right: 20px;
        bottom: 20px;
    }

    .emptyText {
        font-size: 14px;
    }



    /* Tooltip */
    /* .frTooltip .ant-tooltip-hidden,
    .frTooltip {
        left: 50% !important;
        transform: translate(-50%, 0) !important;
    }

    .frTooltip .ant-tooltip-arrow {
        display: none;
    } */


}










/* Mobile 가로 모드 */
@media (max-width:767px) and (orientation: landscape) {
    .frOrderRequest .frOrderList {
        max-height: 150px;
    }
}